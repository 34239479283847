<style scoped>
    :deep(.article-container) {
        display: flex;
        justify-content: space-between;
        max-width: 15vw;
    }

    #modal-create-copy-consult-request :deep(.modal-content), #modal-validate-price-request :deep(.modal-content) {
        min-width: 80vw;
    }

    :deep(.modal-container form > div:first-child) {
        flex: 2;
    }

    :deep(.modal-container form > div:last-child) {
        flex: 3;
    }

    :deep(.articles-list .inputeo), :deep(.documents-list .inputeo) {
        margin: 0px 10px;
        width: 100px;
    }

    .article-detail-container {
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: space-between;
    }

    .article-detail-container div:nth-child(1) {
        flex: 2;
    }

    .article-detail-container div:nth-child(2), .article-detail-container div:nth-child(3), .article-detail-container div:nth-child(4) {
        flex: 1;
    }

    .list-articles-container {
        display: flex;
        width: 100%;
    }

    .list-articles-container :deep(.list-with-delete-container:nth-child(2)) {
        margin-top: 18px;
    }

    .list-articles-container :deep(.list-with-delete) {
        max-height: unset;
    }

    #modal-create-copy-consult-request :deep(.list-with-delete) {
        margin-right: 20px;
    }

    #attachment-container {
        margin: 30px 30px;
    }

    .attachment-element {
        margin: 15px 0px;
        color: #1E73BE;
    }

    .attachment-element:hover {
        cursor: pointer;
    }

    .attachment-element > svg {
        margin-right: 10px;
    }

    :deep(.aggrid-filter .multiselect) {
        width: 240px;
    }

    .price-request-detail {
        margin: 40px 10px 40px 10px;
        font-size: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 5px;
    }

    #work-order-warning {
        color: #ff6b00;
        background-color: rgba(255, 145, 0, 0.32);
        width: 92%;
        border-radius: 5px;
        margin: -5px 2% 5px 2%;
        padding: 5px 2%;
    }

    #topbar-button-container {
        display: flex;
    }

    #topbar-button-container :deep(button:nth-child(1)), #topbar-button-container :deep(button:nth-child(2)) {
        margin-right: 20px;
    }
</style>

<template>
    <CustomTopbar>
        <template v-slot:custom-topbar>
            <div id="topbar-button-container">
                <BaseButton buttonText="Grouper des demandes" @click="displayModalGroupPriceRequest">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'layer-group']" />
                    </template>
                </BaseButton>
                <BaseButton buttonText="Nouvelle demande" @click="displayModalCreatePriceRequest">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'plus']" />
                    </template>
                </BaseButton>
            </div>
        </template>
    </CustomTopbar>
    <Aggrid
        apiRoute="priceRequest/list"
        :apiParams="filters"
        :columnDefs="columnDefs" 
        :context="context"
        :frameworkComponents="frameworkComponents"
        aggridHeightSubstract="100px"
        @rowClicked="((event) => {
            if (event.status)
              displayModalConsultPriceRequest(event.id);
            else
              displayModalEditPriceRequest(event.id);
        })"
    >
        <template v-slot:filter>
            <div>
                <BaseSelect 
                    v-model="affairSearch"
                    label="Affaire"
                    name="affairId"
                    fieldValue="id"
                    fieldLabel="name"
                    @onChange="onFiltersChange($event)"
                    :searchable="true"
                    api="affair/search"
                    :minChars="2"
                />
            </div>
            <div>
                <BaseSelect 
                    v-model="supplierSearch"
                    label="Fournisseur"
                    name="supplierId"
                    fieldValue="id"
                    fieldLabel="name"
                    @onChange="onFiltersChange($event)"
                    :searchable="true"
                    api="supplier/search"
                    :minChars="1"
                />
            </div>
            <div>
                <BaseSelect 
                    v-model="priceRequestSearch"
                    label="Numéro de demande"
                    name="priceRequestId"
                    fieldValue="id"
                    fieldLabel="priceRequestNumber"
                    @onChange="onFiltersChange($event)"
                    :searchable="true"
                    api="priceRequest/search"
                    :apiParams="{
                        priceRequestMode: 'priceRequest'   
                    }"
                    :minChars="2"
                />
            </div>
        </template>
    </Aggrid>
    <Modal id="modal-create-copy-consult-request" v-show="modalCreateOrCopyOrConsultPriceRequestDisplayed">
        <template v-slot:modalIcon>
            <font-awesome-icon v-if="createOrEditOrCopyOrConsultMode !== 'consult'" :icon="['fas', 'plus']" />
            <font-awesome-icon v-if="createOrEditOrCopyOrConsultMode === 'consult'" :icon="['fas', 'search']" />
        </template>
        <template v-slot:modalTitle>
            <div v-if="createOrEditOrCopyOrConsultMode !== 'consult' && createOrEditOrCopyOrConsultMode !== 'edit'">CRÉER UNE DEMANDE DE PRIX</div>
            <div v-if="createOrEditOrCopyOrConsultMode === 'edit'">ÉDITER UNE DEMANDE DE PRIX</div>
            <div v-if="createOrEditOrCopyOrConsultMode === 'consult'">CONSULTER UNE DEMANDE DE PRIX</div>
        </template>
        <template v-slot:modalContent>
            <form class="form-column">
                <div>
                    <BaseSelect 
                        v-model="priceRequest.affair"
                        label="Affaire"
                        name="affair"
                        api="affair/search"
                        fieldValue="id" 
                        fieldLabel="name"
                        :searchable="true" 
                        :minChars="2"
                        :disabled="createOrEditOrCopyOrConsultMode === 'consult'"
                        @onChange="onAffairChange"
                    />
                    <BaseSelect 
                        v-model="priceRequest.suppliers"
                        label="Fournisseur"
                        name="suppliers"
                        api="supplier/search"
                        fieldValue="id" 
                        fieldLabel="name"
                        :searchable="true" 
                        :required="true" 
                        :multiple="['create', 'copy'].includes(createOrEditOrCopyOrConsultMode) ? true : false" 
                        oneItemSelectedText="fournisseur sélectionné"
                        multipleItemsSelectedText="fournisseurs sélectionnés"
                        :error="formErrorsModalCreateOrCopyOrConsultPriceRequest?.suppliers?.error?.message"
                        @onChange="onFormCreateOrCopyOrConsultPriceRequestInputChange"
                        :displayError="displayErrorModalCreateOrCopyOrConsultPriceRequest"
                        :minChars="1"
                        :displayTag="createOrEditOrCopyOrConsultMode === 'create'"
                        :disabled="createOrEditOrCopyOrConsultMode === 'consult'"
                    />
                    <BaseSelect
                        v-if="createOrEditOrCopyOrConsultMode !== 'consult'"
                        v-model="form.articleCurrent"
                        label="Ajouter un article"
                        name="searchArticle"
                        @onChange="onArticleAdd" 
                        api="article/search"
                        :apiParams="{
                            establishment: form.articleCurrentEstablishmentFilter?.value
                        }"
                        fieldValue="id"
                        fieldLabel="label"
                        :searchable="true"
                        :maxHeight="300"
                    />
                    <div id="work-order-warning" v-show="displayWorkOrderWarning">N'oubliez pas d'ajouter des pièces-jointes pour les pièces neuves.</div>
                    <ListWithDelete class="documents-list" v-model="priceRequest.documents" label="Pièces jointes" @onDelete="handleDocuments" :disabled="createOrEditOrCopyOrConsultMode === 'consult'">
                        <template v-slot:content="{ item }">
                            <BaseFile 
                                v-model="item.path"
                                name="path"
                                :files="item.file"
                                v-on:update:files="item.file = $event"
                                accept="application/pdf"
                                @onChange="onDocumentChange"
                                :disabled="!item.editable"
                            >
                                <template v-slot:iconBefore>
                                    <font-awesome-icon :icon="['fas', 'file-pdf']" />
                                </template>
                            </BaseFile>
                        </template>
                    </ListWithDelete>
                </div>
                <div>
                    <BaseSelect 
                        v-model="priceRequest.establishment"
                        label="Établissement"
                        :defaultOptions="establishments"
                        name="establishment"
                        :error="formErrorsModalCreateOrCopyOrConsultPriceRequest?.establishment?.error?.message"
                        @onChange="onFormCreateOrCopyOrConsultPriceRequestInputChange"
                        :displayError="displayErrorModalCreateOrCopyOrConsultPriceRequest"
                        :required="true"
                        :disabled="createOrEditOrCopyOrConsultMode === 'consult' || priceRequest.affair != null"
                    />
                    <div class="list-articles-container">
                        <ListWithDelete class="articles-list" v-model="priceRequest.articles1" @onDelete="handleFakeDocuments(); checkWorkOrderArticles();" label="Liste des article" :disabled="createOrEditOrCopyOrConsultMode === 'consult'">
                            <template v-slot:content="{ item, index }">
                                <div class="article-detail-container">
                                    <div>{{ item.label }}</div>
                                    <BaseInput
                                        v-model="priceRequest.articles1[index].quantityRequested"
                                        type="text" 
                                        :label="'Qté' + (item.unit ? ` (${item.unit})` : '')"
                                        :name="'quantityRequested_1_' + index" 
                                        validator="decimal_2"
                                        :error="formErrorsModalCreateOrCopyOrConsultPriceRequest['quantityRequested_1_' + index]?.error?.message"
                                        @onChange="onFormCreateOrCopyOrConsultPriceRequestInputChange"
                                        :required="true"
                                        :displayError="displayErrorModalCreateOrCopyOrConsultPriceRequest"
                                        :isSmall="true"
                                        :disabled="createOrEditOrCopyOrConsultMode === 'consult'"
                                    />
                                </div>
                            </template>
                        </ListWithDelete>
                        <ListWithDelete class="articles-list" v-model="priceRequest.articles2" @onDelete="handleFakeDocuments(); checkWorkOrderArticles();" v-show="priceRequest.articles2.length > 0" :disabled="createOrEditOrCopyOrConsultMode === 'consult'">
                            <template v-slot:content="{ item, index }">
                                <div class="article-detail-container">
                                    <div>{{ item.label }}</div>
                                    <BaseInput
                                        v-model="priceRequest.articles2[index].quantityRequested"
                                        type="text"
                                        :label="'Qté' + (item.unit ? ` (${item.unit})` : '')"
                                        :name="'quantityRequested_2_' + index" 
                                        validator="decimal_2"
                                        :error="formErrorsModalCreateOrCopyOrConsultPriceRequest['quantityRequested_2_' + index]?.error?.message"
                                        @onChange="onFormCreateOrCopyOrConsultPriceRequestInputChange"
                                        :required="true"
                                        :displayError="displayErrorModalCreateOrCopyOrConsultPriceRequest"
                                        :isSmall="true"
                                        :disabled="createOrEditOrCopyOrConsultMode === 'consult'"
                                    />
                                </div>
                            </template>
                        </ListWithDelete>
                    </div>
                </div>
            </form>
        </template>
        <template v-slot:modalButtons>
            <BaseButton class="white-button" @click="closeModalCreateOrCopyOrConsultPriceRequest" buttonText="Fermer">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'times']" />
                </template>
            </BaseButton>
            <BaseButton v-if="createOrEditOrCopyOrConsultMode !== 'consult'" class="orange-button" @click="submitModalCreateOrCopyOrConsultPriceRequest" buttonText="Valider">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'check']" />
                </template>
            </BaseButton>
        </template>
    </Modal>
    <Modal v-show="modalAttachmentsPriceRequestDisplayed" :hideIcon="true" class="auto-height">
        <template v-slot:modalTitle>
            <div>DOCUMENTS</div>
        </template>
        <template v-slot:modalContent>
            <div id="attachment-container">
                <div class="attachment-element" v-for="attachment in attachments" :key="attachment" :data-path="attachment.path" @click="displayDocument">
                    <font-awesome-icon :icon="['fas', 'file-pdf']" />
                    {{ attachment.name }}
                </div>
            </div>
        </template>
        <template v-slot:modalButtons>
            <BaseButton class="white-button" @click="closeModalAttachmentsPriceRequest" buttonText="Fermer">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'times']" />
                </template>
            </BaseButton>
        </template>
    </Modal>
    <Modal id="modal-validate-price-request" v-show="modalValidateOrOrderPriceRequestDisplayed" :hideIcon="true">
        <template v-slot:modalTitle>
            <div v-show="validateOrOrderMode === 'validate'">VALIDER LA DEMANDE</div>
            <div v-show="validateOrOrderMode === 'order'">COMMANDER LA DEMANDE</div>
        </template>
        <template v-slot:modalContent>
            <form class="form-column">
                <div>
                    <BaseDatepicker 
                        v-model="validateOrOrderPriceRequest.date"
                        label="Date de validation de la commande"
                        :displayTime="true"
                        name="date"
                        :error="formErrorsModalValidateOrOrderPriceRequest.date?.error?.message"
                        @onChange="onFormValidateOrOrderPriceRequestInputChange"
                        :required="true"
                        :displayError="displayErrorModalValidateOrOrderPriceRequest"
                        v-show="validateOrOrderMode === 'order'"
                    >
                    </BaseDatepicker>
                    <BaseInput
                        v-model="validateOrOrderPriceRequest.comment"
                        v-if="validateOrOrderMode === 'order'"
                        label="Commentaire à joindre par mail à la commande (optionnel)"
                        type="textarea"
                    />
                    <BaseSelect
                        v-if="!validateOrOrderPriceRequest.isGrouped && validateOrOrderMode === 'validate'"
                      v-model="form.articleCurrentEstablishmentFilter"
                      label="Etablissement"
                      :defaultOptions="establishments"
                      name="searchArticleEstablishment"
                      :display-tag="true"
                      fieldLabel="label"
                      fieldValue="id"
                    />
                    <BaseSelect 
                        v-if="!validateOrOrderPriceRequest.isGrouped && validateOrOrderMode === 'validate'"
                        v-model="form.articleCurrent"
                        label="Ajouter un article"
                        name="searchArticle"
                        @onChange="onArticleAdd" 
                        api="article/search"
                        :apiParams="{
                            establishment: form.articleCurrentEstablishmentFilter?.value
                        }"
                        fieldValue="id"
                        fieldLabel="label"
                        :searchable="true"
                        :maxHeight="200"
                    />
                    <div class="list-articles-container">
                        <ListWithDelete class="articles-list" v-model="validateOrOrderPriceRequest.articles1" label="Liste des articles" :disabled="validateOrOrderPriceRequest.isGrouped || validateOrOrderMode === 'order'">
                            <template v-slot:content="{ item, index }">
                                <div class="article-detail-container">
                                    <div>{{ item.label }}</div>
                                    <BaseInput
                                        v-model="validateOrOrderPriceRequest.articles1[index].quantityRequested"
                                        type="text"
                                        :label="'Qté' + (item.unit ? ` (${item.unit})` : '')"
                                        :name="'quantityRequested_1_' + index" 
                                        validator="decimal_2"
                                        :error="formErrorsModalValidateOrOrderPriceRequest['quantityRequested_1_' + index]?.error?.message"
                                        @onChange="onFormValidateOrOrderPriceRequestInputChange"
                                        :required="true"
                                        :displayError="displayErrorModalValidateOrOrderPriceRequest"
                                        :isSmall="true"
                                        :disabled="validateOrOrderPriceRequest.isGrouped || validateOrOrderMode === 'order'"
                                    />
                                    <BaseInput 
                                        v-model="validateOrOrderPriceRequest.articles1[index].delay"
                                        type="text" 
                                        label="Délai (jours)"
                                        :name="'delay_1_' + index" 
                                        validator="decimal"
                                        :error="formErrorsModalValidateOrOrderPriceRequest['delay_1_' + index]?.error?.message"
                                        @onChange="onFormValidateOrOrderPriceRequestInputChange"
                                        :required="true"
                                        :displayError="displayErrorModalValidateOrOrderPriceRequest"
                                        :isSmall="true"
                                        :disabled="validateOrOrderPriceRequest.isGrouped"
                                    />
                                    <BaseInput 
                                        v-model="validateOrOrderPriceRequest.articles1[index].buyPrice"
                                        type="text" 
                                        label="Prix achat (HT)"
                                        name="'buyPrice_1_' + index" 
                                        validator="price"
                                        :error="formErrorsModalValidateOrOrderPriceRequest['buyPrice_1_' + index]?.error?.message"
                                        @onChange="onFormValidateOrOrderPriceRequestInputChange"
                                        :required="true"
                                        :displayError="displayErrorModalValidateOrOrderPriceRequest"
                                        :isSmall="true"
                                        :disabled="validateOrOrderPriceRequest.isGrouped || validateOrOrderMode === 'order'"
                                    />
                                </div>
                            </template>
                        </ListWithDelete>
                        <ListWithDelete class="articles-list" v-model="validateOrOrderPriceRequest.articles2" v-show="validateOrOrderPriceRequest.articles2.length > 0" :disabled="validateOrOrderPriceRequest.isGrouped || validateOrOrderMode === 'order'">
                            <template v-slot:content="{ item, index }">
                                <div class="article-detail-container">
                                    <div>{{ item.label }}</div>
                                    <BaseInput
                                        v-model="validateOrOrderPriceRequest.articles2[index].quantityRequested"
                                        type="text"
                                        :label="'Qté' + (item.unit ? ` (${item.unit})` : '')"
                                        :name="'quantityRequested_2_' + index" 
                                        validator="decimal_2"
                                        :error="formErrorsModalValidateOrOrderPriceRequest['quantityRequested_2_' + index]?.error?.message"
                                        @onChange="onFormValidateOrOrderPriceRequestInputChange"
                                        :required="true"
                                        :displayError="displayErrorModalValidateOrOrderPriceRequest"
                                        :isSmall="true"
                                        :disabled="validateOrOrderPriceRequest.isGrouped || validateOrOrderMode === 'order'"
                                    />
                                    <BaseInput 
                                        v-model="validateOrOrderPriceRequest.articles2[index].delay"
                                        type="text" 
                                        label="Délai (jours)"
                                        :name="'delay_2_' + index" 
                                        validator="decimal"
                                        :error="formErrorsModalValidateOrOrderPriceRequest['delay_2_' + index]?.error?.message"
                                        @onChange="onFormValidateOrOrderPriceRequestInputChange"
                                        :required="true"
                                        :displayError="displayErrorModalValidateOrOrderPriceRequest"
                                        :isSmall="true"
                                        :disabled="validateOrOrderPriceRequest.isGrouped"
                                    />
                                    <BaseInput 
                                        v-model="validateOrOrderPriceRequest.articles2[index].buyPrice"
                                        type="text" 
                                        label="Prix achat (HT)"
                                         name="'buyPrice_2_' + index" 
                                        validator="price"
                                        :error="formErrorsModalValidateOrOrderPriceRequest['buyPrice_2_' + index]?.error?.message"
                                        @onChange="onFormValidateOrOrderPriceRequestInputChange"
                                        :required="true"
                                        :displayError="displayErrorModalValidateOrOrderPriceRequest"
                                        :isSmall="true"
                                        :disabled="validateOrOrderPriceRequest.isGrouped || validateOrOrderMode === 'order'"
                                    />
                                </div>
                            </template>
                        </ListWithDelete>
                    </div>
                </div>
            </form>
        </template>
        <template v-slot:modalButtons>
            <BaseButton class="white-button" @click="closeModalValidateOrOrderPriceRequest" buttonText="Fermer">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'times']" />
                </template>
            </BaseButton>
            <BaseButton v-if="validateOrOrderMode === 'order'" class="orange-button" @click="openModalMailReader" buttonText="Valider">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'check']" />
                </template>
            </BaseButton>

          <BaseButton v-if="validateOrOrderMode === 'validate'" class="orange-button" @click="validateOrOrder" buttonText="Valider">
            <template v-slot:iconBefore>
              <font-awesome-icon :icon="['fas', 'check']" />
            </template>
          </BaseButton>
        </template>
    </Modal>

    <Modal v-if="modalGroupPriceRequestDisplayed" :hideIcon="true">
        <template v-slot:modalTitle>
            <div>GROUPER DES DEMANDES DE PRIX</div>
        </template>
        <template v-slot:modalContent>
            <form class="form-column">
                <div>
                    <BaseSelect
                        v-model="groupPriceRequest.establishment"
                        label="Etablissement"
                        :defaultOptions="establishments"
                        name="establishment"
                        :required="true" 
                        :error="formErrorsModalGroupPriceRequest?.establishment?.error?.message"
                        @onChange="onFormGroupPriceRequestInputChange"
                        :displayError="displayErrorModalGroupPriceRequest"
                    />
                    <BaseSelect 
                        v-model="groupPriceRequest.supplier"
                        label="Fournisseur"
                        name="supplier"
                        api="supplier/search"
                        fieldValue="id" 
                        fieldLabel="name"
                        :searchable="true" 
                        :required="true" 
                        :error="formErrorsModalGroupPriceRequest?.supplier?.error?.message"
                        @onChange="onFormGroupPriceRequestInputChange"
                        :displayError="displayErrorModalGroupPriceRequest"
                        :minChars="1"
                    />
                    <ListWithDelete v-if="groupPriceRequest.supplierPriceRequests !== null && groupPriceRequest.supplierPriceRequests.length > 0" label="Liste des demandes de prix" v-model="groupPriceRequest.supplierPriceRequests">
                        <template v-slot:content="{ item }">
                            <div>{{ item.label }}</div>
                        </template>
                    </ListWithDelete>
                    <div v-if="groupPriceRequest.supplierPriceRequests !== null && groupPriceRequest.supplierPriceRequests.length === 0">Aucune demande de prix en attente pour ce fournisseur</div> 
                </div>
            </form>
        </template>
        <template v-slot:modalButtons>
            <BaseButton class="white-button" @click="closeModalGroupPriceRequest" buttonText="Fermer">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'times']" />
                </template>
            </BaseButton>
            <BaseButton class="orange-button" @click="validateModalGroupPriceRequest" buttonText="Valider">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'check']" />
                </template>
            </BaseButton>
        </template>
    </Modal>

    <MailReader 
        v-show="isModalVisible"
        mode="priceRequest"
        v-model:title="title"
        v-model:message="message"
        :recipients="value"
        :attachments="attachments"
        :read-only="false"
        :supplier-id="priceRequestDetail?.supplier?.id"
        @close-modal="isModalVisible = false"
        @add-recipient="addRecipient"
    >

      <template v-slot:modalContent>
        <BaseInput
            v-model:model-value="value"
            name="value"
            type="input"
            validator="email"
            is-small
        >
        </BaseInput>
      </template>

      <template v-slot:modalButtons>
        <BaseButton class="orange-button" @click="onMailReaderSend" buttonText="Valider">
          <template v-slot:iconBefore>
            <font-awesome-icon :icon="['fas', 'check']" />
          </template>
        </BaseButton>
      </template>
    </MailReader>
    <Dialog ref="dialog" />
</template>

<script>
    import Aggrid from '../../components/Aggrid/Aggrid';
    import ListPriceRequestsActionButtonsRenderer from './ListPriceRequestsActionButtonsRenderer.vue';
    import ListTextWithIconRenderer from '../../components/Aggrid/ListTextWithIconRenderer';
    import BaseSelect from '../../components/Base/BaseSelect.vue';
    import BaseInput from '../../components/Base/BaseInput.vue';
    import BaseButton from '../../components/Base/BaseButton.vue';
    import BaseDatepicker from '../../components/Base/BaseDatepicker.vue';
    import BaseFile from '../../components/Base/BaseFile';
    import Modal from '../../components/App/Modal.vue';
    import Dialog from '../../components/App/Dialog.vue';
    import ListWithDelete from '../../components/App/ListWithDelete'
    import ButtonTopbar from '../../components/Topbar/ButtonTopbar.vue';
    import CustomTopbar from '../../components/Topbar/CustomTopbar.vue';
    import axios from 'axios';
    import { createToast } from 'mosha-vue-toastify';
    import config from '../../config';
    import { format } from 'date-fns';
    import changeTypeEnum from '../../enums/changeTypeEnum';
    import MailReader from "@/components/App/MailReader.vue";
    import suppliers from "@/views/Supplier/Suppliers.vue";

    export default {
        name: 'PriceRequests',
      computed: {
        suppliers() {
          return suppliers
        }
      },
        components: {
          MailReader,
            Aggrid,
            ListPriceRequestsActionButtonsRenderer,
            ListTextWithIconRenderer,
            BaseSelect,
            BaseInput,
            BaseButton,
            BaseDatepicker,
            BaseFile,
            Modal,
            Dialog,
            ButtonTopbar,
            ListWithDelete,
            CustomTopbar
        },
        data() {
            return {

                
                columnDefs: [
                    { field: 'createdAt', headerName : `Date de création`, flex: 2, lockPosition: true, filter: 'agDateColumnFilter', sortable: true },
                    { field: 'priceRequestNumber', headerName : `Numéro`, flex: 1, lockPosition: true, sortable: true },
                    { headerName: 'Groupée', flex: 1, lockPosition: true, cellRenderer: 'ListTextWithIconRenderer', cellRendererParams: { field: 'isGrouped', mode: 'boolean' }, filterParams: { filterOptions: ['equals'] }},
                    { field: 'affairNumber', headerName : `Affaire`, flex: 1, lockPosition: true, customFilter: 'affair.affairNumber' },
                    { field: 'supplier', headerName : `Fournisseur`, flex: 2, lockPosition: true, customFilter: 'supplier.name' },
                    { headerName : 'Statut', flex: 2, lockPosition: true, cellRendererFunctionName: 'StatutRendererFunction', suppressMenu: true},
                    { field: 'requestDate', headerName : `Date d'envoi`, flex: 2, lockPosition: true, filter: 'agDateColumnFilter'},
                    { field: 'validationOrRefusalDate', headerName : `Date valid./refus`, flex: 2, lockPosition: true, filter: 'agDateColumnFilter' },
                    { flex: 3, lockPosition: true, cellRenderer: 'ListPriceRequestsActionButtonsRenderer', suppressMenu: true}
                ],
                frameworkComponents: {
                    ListPriceRequestsActionButtonsRenderer: ListPriceRequestsActionButtonsRenderer,
                    ListTextWithIconRenderer: ListTextWithIconRenderer,
                },
                cellRendererFunctions: new Map([
                    [
                        'StatutRendererFunction',
                        function(param) {
                            switch (param?.data?.status) {
                                case 'ordered':
                                return 'Commandé'
                                case 'validated':
                                return 'Validé'
                                case 'refused':
                                return 'Refusé'
                                case 'pending':
                                return 'En attente'
                                case 'completed':
                                return 'Complète'
                            }
                        }
                    ]
                ]),
                context: null,
                modalCreateOrCopyOrConsultPriceRequestDisplayed: false,
                modalAttachmentsPriceRequestDisplayed: false,
                priceRequest: null,
                attachments: null,
                title: "",
                message: "",
                value:"",
                validateOrOrderPriceRequest: null,
                form: {
                    articleCurrent: null
                },
                displayErrorModalCreateOrCopyOrConsultPriceRequest: false,
                modalValidateOrOrderPriceRequestDisplayed: false,
                displayErrorModalValidateOrOrderPriceRequest: false,
                formErrorsModalCreateOrCopyOrConsultPriceRequest: {},
                formErrorsModalValidateOrOrderPriceRequest: {},
                affairSearch: null,
                supplierSearch: null,
                priceRequestSearch: null,
                filters: {
                    priceRequestMode: 'priceRequest',
                    affairId: null,
                    supplierId: null,
                    priceRequestId: null
                },
                createOrEditOrCopyOrConsultMode: null,
                validateOrOrderMode: null,
                modalSendPriceRequestDisplayed: false,
                modalMailReaderDisplayed: false,
                priceRequestDetail: null,
                establishments: [
                    { label: 'EBC', value: 'ebc' },
                    { label: 'SMG', value: 'smg' }
                ],
                displayWorkOrderWarning: false,
                supplierContacts: [],
                supplierContactsSelected: null,
                isModalVisible: false,
                mailReaderContext: null,
                notValidEmail: null,
                modalGroupPriceRequestDisplayed: false,
                groupPriceRequest: null,
                formErrorsModalGroupPriceRequest: {},
                displayErrorModalGroupPriceRequest: false
            }
        },
        beforeMount() {
            this.context = {
                componentParent: this
            };

            this.resetModalCreateOrCopyOrConsultPriceRequest();
            this.resetModalValidateOrOrderPriceRequest();
        },
        methods: {
          addRecipient(mail){
            this.value.length === 0 ? this.value += mail : this.value += ";"+mail
          },
          openModalMailReader(){
              this.isModalVisible = true;
          },
          validateOrOrder(){
            if (!this.checkFormValidateOrOrderPriceRequest()) return;

            let method = null;
            let successMessage = null;

            if (this.validateOrOrderMode === 'validate') {
              method = 'validate';
              successMessage = 'La demande de prix a bien été validée';
            } else {
              method = 'order';
              successMessage = 'La commande a bien été réalisée';
            }

            axios
                .put(`priceRequest/${method}`, this.formateValidateOrOrderPriceRequest(this.validateOrOrderPriceRequest), {
                  toastSuccessMessage: successMessage,
                  toastError: true,
                  showPreloader: true
                })
                .then(() => {
                  this.closeModalValidateOrOrderPriceRequest();
                  this.emitter.emit('ag-grid-reload');
                })
                .catch(() => {});
          },
            deletePriceRequest(priceRequestId) {
                this.$refs.dialog.show({
                    type: 'confirm',
                    title: 'Confirmation',
                    message: `Êtes-vous sûr de vouloir supprimer cette demande de prix ?`
                }).then(() => {
                    axios
                    .put('priceRequest/changeDeleted/' + priceRequestId, {
                        deleted: true
                    }, { 
                        toastSuccessMessage: `Modification effectuée`, 
                        toastError: false, 
                        showPreloader: true 
                    })
                    .then(() => {
                        this.emitter.emit('ag-grid-reload');
                    })
                    .catch(() => {});
                })
                .catch(() => {});
            },
            closeModalCreateOrCopyOrConsultPriceRequest() {
                this.resetModalCreateOrCopyOrConsultPriceRequest();
                this.modalCreateOrCopyOrConsultPriceRequestDisplayed = false;
                this.displayErrorModalCreateOrCopyOrConsultPriceRequest = false;
            },
            submitModalCreateOrCopyOrConsultPriceRequest() {
                if (this.createOrEditOrCopyOrConsultMode === 'create' || this.createOrEditOrCopyOrConsultMode === 'copy') {
                    this.createPriceRequest();
                } else if (this.createOrEditOrCopyOrConsultMode === 'edit') {
                    this.editPriceRequest();
                }
            },
            displayModalCreatePriceRequest() {
                this.createOrEditOrCopyOrConsultMode = 'create';
                this.resetModalCreateOrCopyOrConsultPriceRequest();
                this.handleDocuments();
                this.modalCreateOrCopyOrConsultPriceRequestDisplayed = true;
            },
            displayModalCopyPriceRequest(priceRequestId) {
                axios
                .get(`priceRequest/${priceRequestId}`, { 
                    toastError: true, 
                    showPreloader: true 
                })
                .then((priceRequest) => {
                    this.createOrEditOrCopyOrConsultMode = 'copy';
                    this.prepareEditOrCopyOrConsultPriceRequest(priceRequest.data);
                    this.modalCreateOrCopyOrConsultPriceRequestDisplayed = true;
                })
                .catch(() => {});
            },
            displayModalEditPriceRequest(priceRequestId) {
                axios
                .get(`priceRequest/${priceRequestId}`, { 
                    toastError: true, 
                    showPreloader: true 
                })
                .then((priceRequest) => {
                    this.createOrEditOrCopyOrConsultMode = 'edit';
                    this.prepareEditOrCopyOrConsultPriceRequest(priceRequest.data);
                    this.modalCreateOrCopyOrConsultPriceRequestDisplayed = true;
                })
                .catch(() => {});
            },
            displayModalConsultPriceRequest(priceRequestId) {
                axios
                .get(`priceRequest/${priceRequestId}`, { 
                    toastError: true, 
                    showPreloader: true 
                })
                .then((priceRequest) => {
                    this.createOrEditOrCopyOrConsultMode = 'consult';
                    this.prepareEditOrCopyOrConsultPriceRequest(priceRequest.data);
                    this.modalCreateOrCopyOrConsultPriceRequestDisplayed = true;
                })
                .catch(() => {});
            },
            prepareEditOrCopyOrConsultPriceRequest(priceRequest) {
                this.priceRequest = {
                    id: priceRequest.id,
                    establishment: this.establishments.find(establishment => establishment.value === priceRequest.establishment),
                    suppliers: this.createOrEditOrCopyOrConsultMode === 'edit' ? {
                        value: priceRequest.supplier.id,
                        label: priceRequest.supplier.name
                    } : [{
                        value: priceRequest.supplier.id,
                        label: priceRequest.supplier.name
                    }]
                };

                this.priceRequest.articles1 = [];
                this.priceRequest.articles2 = [];

                for (let index = 0; index < priceRequest.articles.length; index++) {
                    let article = priceRequest.articles[index];
                    let articleFormatted = {
                        value: article.id,
                        label: article.label,
                        quantityRequested: article.quantityRequested,
                        unit: article.unit
                    };
                    if (index % 2 === 0) {
                        this.priceRequest.articles1.push(articleFormatted);
                    } else {
                        this.priceRequest.articles2.push(articleFormatted);
                    }
                }

                if (priceRequest.affair != null) {
                    this.priceRequest.affair = {
                        value: priceRequest.affair.id,
                        label: priceRequest.affair.number
                    };
                }

                this.priceRequest.documents = priceRequest.documents.map(document => {
                    return {
                        id: document.id,
                        path: document.name,
                        file: [document.name],
                        editable: false,
                        hide: false
                    };
                });

                this.handleDocuments();
            },
            resetModalCreateOrCopyOrConsultPriceRequest() {
                this.priceRequest = {
                    id: null,
                    affair: null,
                    establishment: null,
                    suppliers: [],
                    articles1: [],
                    articles2: [],
                    documents: []
                };
            },
            formatePriceRequest(priceRequest) {
                let formData = new FormData();
                if (priceRequest?.id) formData.append('id', priceRequest.id);
                if (priceRequest?.affair?.value) formData.append('affairId', priceRequest.affair.value);
                if (priceRequest?.establishment?.value) formData.append('establishment', priceRequest.establishment.value);
                if (this.createOrEditOrCopyOrConsultMode === 'edit') {
                    formData.append('suppliers', JSON.stringify([priceRequest.suppliers.value]));
                } else {
                    formData.append('suppliers', JSON.stringify(priceRequest.suppliers.map(supplier => supplier.value)));
                }
                formData.append('articles', JSON.stringify(priceRequest.articles1.concat(priceRequest.articles2).map((article) => {
                    return {
                        id: article.value,
                        quantity: parseFloat(article.quantityRequested)
                    }
                })));
                formData.append('documents', JSON.stringify(priceRequest.documents.filter(document => document.id != null).map(document => document.id)));
                priceRequest.documents.filter(document => document.id == null && document.file?.length > 0).map(document => formData.append('attachments', document.file[0]));
                formData.append('documentsToCreate', JSON.stringify(priceRequest.documents.filter(document => document.id == null && document.file == null)));
                return formData;
            },
            createPriceRequest() {
                if (!this.checkFormCreateOrCopyOrConsultPriceRequest()) return;

                axios
                .post('priceRequest/create', this.formatePriceRequest(this.priceRequest), { 
                    toastSuccessMessage: `Demande de prix créée`, 
                    toastError: true, 
                    showPreloader: true 
                })
                .then(() => {
                    this.closeModalCreateOrCopyOrConsultPriceRequest();
                    this.emitter.emit('ag-grid-reload');
                })
                .catch(() => {});
            },
            editPriceRequest() {
                if (!this.checkFormCreateOrCopyOrConsultPriceRequest()) return;

                axios
                .put('priceRequest/update', this.formatePriceRequest(this.priceRequest), { 
                    toastSuccessMessage: `Demande de prix modifiée`, 
                    toastError: true, 
                    showPreloader: true 
                })
                .then(() => {
                    this.closeModalCreateOrCopyOrConsultPriceRequest();
                    this.emitter.emit('ag-grid-reload');
                })
                .catch(() => {});
            },
            onFormCreateOrCopyOrConsultPriceRequestInputChange(input) {
                if (input.error?.message !== null) {
                    this.formErrorsModalCreateOrCopyOrConsultPriceRequest[input.name] = input;
                } else {
                    delete this.formErrorsModalCreateOrCopyOrConsultPriceRequest[input.name];
                }
            },
            onFormValidateOrOrderPriceRequestInputChange(input) {
                if (input.error?.message !== null) {
                    this.formErrorsModalValidateOrOrderPriceRequest[input.name] = input;
                } else {
                    delete this.formErrorsModalValidateOrOrderPriceRequest[input.name];
                }
            },
            checkFormCreateOrCopyOrConsultPriceRequest() {
                this.displayErrorModalCreateOrCopyOrConsultPriceRequest = true;

                if (this.priceRequest.articles1.length === 0 && this.priceRequest.articles2.length === 0) {
                    createToast(`Merci d'ajouter au moins un article`, {
                        position: 'bottom-right',
                        type: 'danger',
                        hideProgressBar: true,
                        showIcon: true,
                        transition: 'slide'
                    });
                    
                    return false;
                }

                return Object.keys(this.formErrorsModalCreateOrCopyOrConsultPriceRequest).length === 0;
            },
            onArticleAdd(article) {
                this.$nextTick(() => {
                    this.form.articleCurrent = null;
                });
                
                // On ajoute l'article s'il n'est pas déjà présent
                if (this.validateOrOrderMode === 'validate') {
                    if (article.value != null && this.validateOrOrderPriceRequest.articles1.filter(element => element.value === article.value.value).length === 0 && this.validateOrOrderPriceRequest.articles2.filter(element => element.value === article.value.value).length === 0) {                        
                        let articleToAdd = {
                            ...article.value,
                            quantityRequested: 1,
                            unit: article.value.data.unit,
                            articleKindId: article.value.data.articleKindId,
                            rewind: article.value.data.rewind,
                            externalMachining: article.value.data.externalMachining
                        };
                        
                        if (this.validateOrOrderPriceRequest.articles1.length <= this.validateOrOrderPriceRequest.articles2.length) {
                            this.validateOrOrderPriceRequest.articles1.push(articleToAdd);
                        } else {
                            this.validateOrOrderPriceRequest.articles2.push(articleToAdd);
                        }
                    }
                } else {
                    if (article.value != null && this.priceRequest.articles1.filter(element => element.value === article.value.value).length === 0 && this.priceRequest.articles2.filter(element => element.value === article.value.value).length === 0) {
                        let articleToAdd = {
                            ...article.value,
                            quantityRequested: 1,
                            unit: article.value.data.unit,
                            articleKindId: article.value.data.articleKindId,
                            rewind: article.value.data.rewind,
                            externalMachining: article.value.data.externalMachining
                        };
                        if (articleToAdd.data.rewind || articleToAdd.data.externalMachining) {
                            this.priceRequest.documents.push({
                                type: (articleToAdd.data.rewind ? 'rewind' : (articleToAdd.data.externalMachining ? 'externalMachining' : null)),
                                path: articleToAdd.data.label.toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-\ _`~()]/g,"-") + (articleToAdd.data.rewind ? "-rebobinage.pdf" : (articleToAdd.data.externalMachining ? "-usinage-externe.pdf" : null)),
                                editable: true,
                                file: null,
                                name: (articleToAdd.data.rewind ? 'rebobinage' : (articleToAdd.data.externalMachining ? 'usinage-externe' : null)),
                                articleLabel: articleToAdd.data.label
                            })
                        }

                        if (this.priceRequest.articles1.length <= this.priceRequest.articles2.length) {
                            this.priceRequest.articles1.push(articleToAdd);
                        } else {
                            this.priceRequest.articles2.push(articleToAdd);
                        }
                        this.checkWorkOrderArticles();
                    }
                }
            },
            onDocumentChange() {
                this.handleDocuments();
            },
            handleDocuments() {
                let documents = [];
                let size = 0;
                for (let document of this.priceRequest.documents) {
                    if (document.file === null) {
                        documents.push({
                            ...document,
                            hide: false
                        });
                        continue;
                    }
                    if (document.file.length <= 0) continue;

                    size += document.file[0].size / 1000;

                    if (size >= 9000) {
                        createToast(`La taille totale des pièces jointes ne doit pas excéder 9Mo`, {
                            position: 'bottom-right',
                            type: 'danger',
                            hideProgressBar: true,
                            showIcon: true,
                            transition: 'slide'
                        });

                        break;
                    }

                    documents.push({
                        ...document,
                        hide: false
                    });
                }

                if (this.createOrEditOrCopyOrConsultMode !== 'consult') {
                    documents.push({
                        name: null,
                        description: null,
                        path: 'Ajouter un document',
                        file: [],
                        editable: true,
                        hide: true
                    });
                }

                this.priceRequest.documents = documents;
            },
            handleFakeDocuments() {
                let found1 = this.priceRequest.documents.findIndex(document => document.type === 'externalMachining');
                if (found1 >= 0) {
                    let id1 = this.priceRequest.articles1.find(article => article.type === 'externalMachining');
                    let id2 = this.priceRequest.articles2.find(article => article.type === 'externalMachining');
                    if (!id1 && !id2) this.priceRequest.documents.splice(found1, 1);
                }
                let found2 = this.priceRequest.documents.findIndex(document => document.type === 'rewind');
                if (found2 >= 0) {
                    let id1 = this.priceRequest.articles1.find(article => article.type === 'rewind');
                    let id2 = this.priceRequest.articles2.find(article => article.type === 'rewind');
                    if (!id1 && !id2) this.priceRequest.documents.splice(found2, 1);
                }
            },

            sendPriceRequest(priceRequestId) {
                this.displayModalAttachmentsPriceRequest(priceRequestId)
                this.mailReaderContext = "sendPriceRequest"

                axios
                .get(`priceRequest/${priceRequestId}`, { 
                    toastError: true, 
                    showPreloader: true 
                })
                .then((priceRequest) => {
                    this.value = ""
                    this.priceRequestDetail = priceRequest.data;
                    this.supplierContacts = priceRequest.data.contacts.map(contact => {
                        return {
                            value: contact.id,
                            label: contact.email,
                            main: contact.main
                        }
                    });

                    this.supplierContactsSelected = this.supplierContacts.filter( contact => contact.main === true);
                    this.supplierContactsSelected.map( (contact,index) => { index === this.supplierContactsSelected.length -1 ? this.value += contact.label : this.value += contact.label + ";"})
                    this.title = `Demande de prix ${this?.priceRequestDetail?.priceRequestNumber} - ${this?.priceRequestDetail?.affair?.number}`
                    this.message = "Bonjour,\n" +
                      "\n" +
                      `Veuillez trouver ci-joint notre demande de prix N°${this.priceRequestDetail?.priceRequestNumber}.\n` +
                      "\n" +
                      "Merci par avance pour votre retour.\n" +
                      "\n" +
                      "Bien cordialement,"
                    this.isModalVisible = true;
                    this.modalAttachmentsPriceRequestDisplayed = false;

                })
                .catch(() => {});
            },
            refusePriceRequest(priceRequestId) {
                this.$refs.dialog.show({
                    type: 'prompt',
                    promptMode: 'dateTime',
                    title: 'Refus de la demande',
                    label: 'Date du refus',
                    message: `Merci de spécifier la date du refus de la demande :`,
                    value: format(new Date(), 'dd/MM/yyyy HH:mm')
                }).then((input) => {
                    if (input == null || input.trim().length === 0) {
                        createToast(`Merci de saisir une date valide`, {
                            position: 'bottom-right',
                            type: 'danger',
                            hideProgressBar: true,
                            showIcon: true,
                            transition: 'slide'
                        });
                    } else {
                        axios
                        .put('priceRequest/refuse', {
                            priceRequestId: priceRequestId,
                            date: input
                        }, { 
                            toastSuccessMessage: `La demande a bien été refusée`, 
                            toastError: true, 
                            showPreloader: true 
                        })
                        .then(() => {
                            this.emitter.emit('ag-grid-reload');
                        })
                        .catch(() => {});
                    }
                })
                .catch(() => {});
            },
            displayModalAttachmentsPriceRequest(priceRequestId) {
                axios
                .get(`priceRequest/${priceRequestId}`, { 
                    toastError: true, 
                    showPreloader: true 
                })
                .then((priceRequest) => {
                    let attachments = priceRequest.data.documents.map(document => {
                        return {
                            name: document.name,
                            path: `${document.generated ? config.GENERATED_DIRECTORY : config.UPLOAD_DIRECTORY}/${document.path}`,
                            generated: document.generated
                        };
                    })

                    attachments.push({
                        name: 'Demande de prix',
                        path: `${config.GENERATED_DIRECTORY}/${priceRequest.data.pdfRequest}`
                    });

                    if (priceRequest.data.pdfOrder != null) {
                        attachments.push({
                            name: 'Commande',
                            path: `${config.GENERATED_DIRECTORY}/${priceRequest.data.pdfOrder}`
                        });
                    }

                    this.attachments = attachments;

                    this.modalAttachmentsPriceRequestDisplayed = true;

                })
                .catch(() => {});
            },
            closeModalAttachmentsPriceRequest() {
                this.modalAttachmentsPriceRequestDisplayed = false;
            },
            displayDocument(event) {
                window.open(`${config.BACK_URL}/${event.currentTarget.dataset.path}`, '_blank').focus();
            },
            resetModalValidateOrOrderPriceRequest() {
                this.validateOrOrderPriceRequest = {
                    date: null,
                    comment: null,
                    isOrderValidated: null,
                    articles1: [],
                    articles2: []
                }
            },
            prepareValidateOrOrderPriceRequest(priceRequest) {
                this.validateOrOrderPriceRequest.priceRequestId = priceRequest.id;
                this.validateOrOrderPriceRequest.date = format(new Date(), 'dd/MM/yyyy HH:mm');
                this.validateOrOrderPriceRequest.isOrderValidated = priceRequest.affair != null ? priceRequest.affair.isOrderValidated : true;
                this.validateOrOrderPriceRequest.isGrouped = priceRequest.isGrouped;
                this.prepareArticle(priceRequest);
            },
            prepareArticle(priceRequest) {
                for (let index = 0; index < priceRequest.articles.length; index++) {
                    let article = priceRequest.articles[index];
                    let articleFormatted = {
                        value: article.id,
                        label: article.label,
                        quantityRequested: article.quantityRequested,
                        quantityOrdered: article.quantityOrdered,
                        unit: article.unit,
                        delay: article.delay,
                        buyPrice: article.buyPrice
                    };
                    if (index % 2 === 0) {
                        this.validateOrOrderPriceRequest.articles1.push(articleFormatted);
                    } else {
                        this.validateOrOrderPriceRequest.articles2.push(articleFormatted);
                    }
                }
            },
          displayModalValidateOrOrderPriceRequest(priceRequestId, mode) {
            this.validateOrOrderMode = mode;
            this.displayModalAttachmentsPriceRequest(priceRequestId)

            axios
                .get(`priceRequest/${priceRequestId}`, {
                  toastError: true,
                  showPreloader: true
                })
                .then((priceRequest) => {
                  this.prepareValidateOrOrderPriceRequest(priceRequest.data);
                  this.value = ""
                  this.priceRequestDetail = priceRequest.data;
                  this.supplierContacts = priceRequest.data.contacts.map(contact => {
                    return {
                      value: contact.id,
                      label: contact.email,
                      main: contact.main
                    }
                  });
                  this.supplierContactsSelected = this.supplierContacts.filter( contact => contact.main === true);
                  this.supplierContactsSelected.map( (contact,index) => { index === this.supplierContactsSelected.length -1 ? this.value += contact.label : this.value += contact.label + ";"})

                  this.title = `Passage d'une commande`;
                  this.message = "\n" +
                      "Bonjour,\n" +
                      "\n" +
                      `Veuillez trouver ci-joint notre commande en référence à votre demande de prix N°${this.priceRequestDetail?.priceRequestNumber}.\n` +
                      "\n" +
                      "Merci de nous envoyer un accusé de réception de commande précisant le délai.\n" +
                      "\n" +
                      "Bien cordialement,"
                  this.mailReaderContext = "orderPriceRequest";
                  this.modalValidateOrOrderPriceRequestDisplayed = true;
                  this.modalAttachmentsPriceRequestDisplayed = false;
                })
                .catch(() => {});
            },
            closeModalValidateOrOrderPriceRequest() {
                this.resetModalValidateOrOrderPriceRequest();
                this.modalValidateOrOrderPriceRequestDisplayed = false;
                this.isModalVisible = false;
            },
            formateValidateOrOrderPriceRequest(priceRequest) {
                priceRequest = JSON.parse(JSON.stringify(priceRequest));
                priceRequest.articles = priceRequest.articles1.concat(priceRequest.articles2).map((article) => {
                    let structure = {
                        id: article.value,
                        delay: article.delay ? parseInt(article.delay) : 0
                    };

                    if (this.validateOrOrderMode === 'validate') {
                        structure.buyPrice = article.buyPrice ? parseFloat(article.buyPrice) : 0
                        structure.quantity = article.quantityRequested ? parseFloat(article.quantityRequested) : 0;
                    }
                    
                    return structure;
                });
                delete priceRequest.articles1;
                delete priceRequest.articles2;
                priceRequest.title = this.title;
                priceRequest.message = this.message;
                priceRequest.recipients = this.value.split(";");
                priceRequest.attachments = this.attachments;
                return priceRequest;
            },
          submitModalValidateOrOrderPriceRequest() {
            if (!this.checkFormValidateOrOrderPriceRequest()) return;

            let method = null;
            let successMessage = null;

            if (this.validateOrOrderMode === 'validate') {
              method = 'validate';
              successMessage = 'La demande de prix a bien été validée';
            } else {
              method = 'order';
              successMessage = 'La commande a bien été réalisée';
            }
            if(this.checkMailValid() > 0){
              this.toastNotValidMail()
            }
            else{
              axios
                  .put(`priceRequest/${method}`,this.formateValidateOrOrderPriceRequest(this.validateOrOrderPriceRequest),{
                  }, {
                    toastSuccessMessage: successMessage,
                    toastError: true,
                    showPreloader: true
                  })
                  .then(() => {

                    this.closeModalValidateOrOrderPriceRequest();
                    this.emitter.emit('ag-grid-reload');
                  })
                  .catch(() => {});

            }

          },
          checkMailValid(){
            let notValidMail = 0
            let contacts = this.value?.split(";")
            contacts.forEach( contact => {
              if (!contact.trim().match(/^(([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z])+){1,}$/g)){
                notValidMail++
              }
            })
            return notValidMail

          },
          toastNotValidMail(){
            createToast(`Merci d'entrer des adresses mail valides !`, {
              position: "bottom-right",
              type: "danger",
              hideProgressBar: true,
              showIcon: true,
              transition: "slide"
            });
          },
            onMailReaderSend(){
              switch (this.mailReaderContext){
                case 'sendPriceRequest':
                  this.submitModalSendPriceRequest();
                  break;
                case 'orderPriceRequest':
                  this.submitModalValidateOrOrderPriceRequest();
                  break;
                default:
                  break;
              }
            },
            checkFormValidateOrOrderPriceRequest() {
                this.displayErrorModalValidateOrOrderPriceRequest = true;

                if (this.validateOrOrderPriceRequest.articles1.length === 0 && this.validateOrOrderPriceRequest.articles2.length === 0) {
                    createToast(`Merci d'ajouter au moins un article`, {
                        position: 'bottom-right',
                        type: 'danger',
                        hideProgressBar: true,
                        showIcon: true,
                        transition: 'slide'
                    });
                    
                    return false;
                }

                return Object.keys(this.formErrorsModalValidateOrOrderPriceRequest).length === 0;
            },
            onFiltersChange(input) {
                if (input.changeType === changeTypeEnum.USER) {
                    this.filters[input.name] = input?.value?.value || null;
                }
            },
            closeModalSendPriceRequest() {
                this.modalSendPriceRequestDisplayed = false;
                this.priceRequestDetail = null;
            },
            submitModalSendPriceRequest() {
              this.contacts = this.value.split(";")

              if (this.supplierContactsSelected == null || Object.keys(this.supplierContactsSelected).length === 0) {
                createToast(`Merci de sélectionner au moins un destinataire`, {
                  position: "bottom-right",
                  type: "danger",
                  hideProgressBar: true,
                  showIcon: true,
                  transition: "slide"
                });
              }

              if(this.checkMailValid() > 0){
                this.toastNotValidMail()
              }
              else {
                    axios
                    .put('priceRequest/send', {
                        priceRequestId: this.priceRequestDetail.id,
                        title: this.title,
                        message: this.message,
                        recipients: this.contacts,
                        attachments: this.attachments
                    }, { 
                        toastSuccessMessage: `Demande de prix envoyée`, 
                        toastError: true, 
                        showPreloader: true 
                    })
                    .then(() => {
                        this.closeModalSendPriceRequest();
                        this.emitter.emit('ag-grid-reload');
                    });
                    this.isModalVisible = false;

                }
            },
            onAffairChange(input) {
                if (input.changeType === changeTypeEnum.USER) {
                    if (this.priceRequest.affair == null) {
                        this.priceRequest.establishment = null;
                    } else {
                        this.priceRequest.establishment = this.establishments.find(establishment => establishment.value === this.priceRequest.affair?.data?.establishment);
                    }
                }
            },
            checkWorkOrderArticles() {
                if (this.priceRequest.articles1.some(article => article.articleKindId === -1 && !article.rewind && !article.externalMachining)
                    || this.priceRequest.articles2.some(article => article.articleKindId === -1 && !article.rewind && !article.externalMachining))
                {
                    this.displayWorkOrderWarning = true;
                } else {
                    this.displayWorkOrderWarning = false;
                }
            },
            displayModalGroupPriceRequest() {
                this.prepareGroupPriceRequest();
                this.modalGroupPriceRequestDisplayed = true;
            },
            closeModalGroupPriceRequest() {
                this.displayErrorModalGroupPriceRequest = false;
                this.modalGroupPriceRequestDisplayed = false;
            },
            validateModalGroupPriceRequest() {
                if (!this.checkFormGroupPriceRequest()) return;

                axios
                .post(`priceRequest/groupPriceRequest`, this.formateGroupPriceRequests(this.groupPriceRequest), { 
                    toastError: true, 
                    showPreloader: true 
                })
                .then((priceRequests) => {
                    this.closeModalGroupPriceRequest();
                    this.emitter.emit('ag-grid-reload');
                })
                .catch(() => {});
            },
            formateGroupPriceRequests(_groupPriceRequest) {
                let groupPriceRequest = {};
                groupPriceRequest.establishment = _groupPriceRequest.establishment.value;
                groupPriceRequest.supplierId = _groupPriceRequest.supplier.value;
                groupPriceRequest.priceRequestIds = _groupPriceRequest.supplierPriceRequests.map(priceRequest => priceRequest.value);
                return groupPriceRequest;
            },
            checkFormGroupPriceRequest() {
                this.displayErrorModalGroupPriceRequest = true;

                if (this.groupPriceRequest.supplierPriceRequests < 2) {
                    createToast(`Merci de sélectionner au moins deux demandes de prix`, {
                        position: 'bottom-right',
                        type: 'danger',
                        hideProgressBar: true,
                        showIcon: true,
                        transition: 'slide'
                    });
                    
                    return false;
                }

                return Object.keys(this.displayErrorModalGroupPriceRequest).length === 0;
            },
            onFormGroupPriceRequestInputChange(input) {
                if (input.error?.message !== null) {
                    this.formErrorsModalGroupPriceRequest[input.name] = input;
                } else {
                    delete this.formErrorsModalGroupPriceRequest[input.name];
                }

                if (this.groupPriceRequest?.supplier?.value != null && this.groupPriceRequest?.establishment?.value != null) {
                    axios
                    .get(`priceRequest/listGroupPriceRequestBySupplier?supplierId=${this.groupPriceRequest.supplier.value}&establishment=${this.groupPriceRequest.establishment.value}`, { 
                        toastError: true, 
                        showPreloader: true 
                    })
                    .then((priceRequests) => {
                        this.groupPriceRequest.supplierPriceRequests = priceRequests.data.map(priceRequest => {
                            return {
                                value: priceRequest.id,
                                label: priceRequest.priceRequestNumber
                            };
                        });
                    })
                    .catch(() => {});
                }
            },
            prepareGroupPriceRequest() {
                this.groupPriceRequest = {
                    supplier: null,
                    supplierPriceRequests: null
                };
            }
        }
    }
</script>