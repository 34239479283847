<template>
    <div class="container-action-button" v-if="params.data">
        <BaseButton @click="onOrderPriceRequest" buttonText="" title="Passer la commande" v-if="this.params.data.status === 'validated'">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'truck']" />
            </template>
        </BaseButton>
        <BaseButton @click="onValidatePriceRequest" buttonText="" title="Valider la demande" :disabled="this.params.data.status !== 'pending'" v-if="this.params.data.status !== 'validated'">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'check']" />
            </template>
        </BaseButton>
        <BaseButton @click="onRefusePriceRequest" buttonText="" title="Refuser la demande" :disabled="this.params.data.status !== 'pending' && this.params.data.status !== 'validated'">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'times']" />
            </template>
        </BaseButton>
        <BaseButton @click="onSendPriceRequest" buttonText="" title="Envoyer la demande" :disabled="this.params.data.status !== null">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'envelope']" />
            </template>
        </BaseButton>
        <BaseButton @click="onConsultPriceRequest" buttonText="" title="Consulter la commande" v-if="this.params.data.status !== null">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'search']" />
            </template>
        </BaseButton>
        <BaseButton @click="onEditPriceRequest" buttonText="" title="Éditer la commande" v-if="this.params.data.status === null && !this.params.data.isGrouped">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'edit']" />
            </template>
        </BaseButton>
        <BaseButton @click="onCopyPriceRequest" buttonText="" title="Dupliquer la demande" v-if="!this.params.data.isGrouped">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'copy']" />
            </template>
        </BaseButton>
        <BaseButton @click="onDownloadPriceRequest" buttonText="" title="Télécharger le PDF">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'file-pdf']" />
            </template>
        </BaseButton>
        <BaseButton @click="onDeletePriceRequest" buttonText="" title="Supprimer la demande" :disabled="this.params.data.status !== null">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'trash']" />
            </template>
        </BaseButton>
    </div>
</template>

<script>
    import BaseButton from '../../components/Base/BaseButton.vue';
    import * as Vue from "vue";
    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

    export default {
        name: 'ListPriceRequestsActionButtonsRenderer',
        components: {
            BaseButton: Vue.shallowRef(BaseButton),
            FontAwesomeIcon: Vue.shallowRef(FontAwesomeIcon)
        },
        methods: {
            onConsultPriceRequest() {
                this.params.context.componentParent.displayModalConsultPriceRequest(this.params.data.id);
            },
            onEditPriceRequest() {
                this.params.context.componentParent.displayModalEditPriceRequest(this.params.data.id);
            },
            onOrderPriceRequest() {
                this.params.context.componentParent.displayModalValidateOrOrderPriceRequest(this.params.data.id, 'order');
            },
            onValidatePriceRequest() {
                this.params.context.componentParent.displayModalValidateOrOrderPriceRequest(this.params.data.id, 'validate');
            },
            onRefusePriceRequest() {
                this.params.context.componentParent.refusePriceRequest(this.params.data.id);
            },
            onSendPriceRequest() {
                this.params.context.componentParent.sendPriceRequest(this.params.data.id);
            },
            onCopyPriceRequest() {
                this.params.context.componentParent.displayModalCopyPriceRequest(this.params.data.id);
            },
            onDownloadPriceRequest() {
                this.params.context.componentParent.displayModalAttachmentsPriceRequest(this.params.data.id);
            },
            onDeletePriceRequest() {
                this.params.context.componentParent.deletePriceRequest(this.params.data.id);
            }
        }
    }        
</script>